import RichText from '../../UI/Richtext';
import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import QuotesSVG from './QuotesSVG';
import ReviewModal from './ReviewModal';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { IReviewFragment } from 'lib/reviewinc';
import Score from 'components/UI/Reviews/Score';

/*
-- https://github.com/iamdustan/smoothscroll --
This polyfill was added because safari update version 15.4 (March 14, 2022)
has a bug that breaks CSS for scrollbehavior: smooth;
The polyfill normally will detect if the spec is natively supported
and take action only when necessary. Currently safari 15.4 DOES support
the scrollbehavior but the implementation is broken so we have to ALWAYS
override the native scrolling methods.
*/

declare global {
  interface Window {
    __forceSmoothScrollPolyfill__: boolean;
  }
}
/*
-- End polyfill --
*/

export interface TestimonialProps {
  testimonials: IReviewFragment[];
  title?: string | undefined;
  backgroundColor?: 'primary' | 'neutral' | 'muted' | undefined;
}

export default function Testimonial({
  testimonials,
  title,
  backgroundColor,
}: TestimonialProps): ReactElement {
  useEffect(() => {
    window.__forceSmoothScrollPolyfill__ = true;
    const smoothscroll = import('smoothscroll-polyfill');
    smoothscroll.then((ss) => ss.polyfill());
  });

  const [scroll, setScroll] = useState(0);
  const [arrowDisabled, setArrowDisabled] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  let cardLength: number | undefined;

  useEffect(() => {
    cardLength = document.getElementById('card0')?.offsetWidth;
  });

  const scroller = (direction: string): void => {
    if (refContainer.current && cardLength != null) {
      if (direction === 'right') {
        refContainer.current.scrollBy({ left: cardLength, behavior: 'smooth' });
        setScroll(scroll + 1);
      } else {
        refContainer.current.scrollBy({ left: -cardLength, behavior: 'smooth' });
        setScroll(scroll - 1);
      }

      //Disables the buttons during the animation to ensure animation/alignment is complete before the buttons can be used again.
      setArrowDisabled(true);
      setTimeout(() => {
        setArrowDisabled(false);
      }, 300);
    }
  };

  /*
  -- Begin Logic for ReviewModal Component --
  Because the data for reviews is mapped from testimonials we add a function
  on each iteration that will then call the modal populated with the correct info
  */
  const [displayModal, setDisplayModal] = useState(false);
  const [modalText, setModalText] = useState<Document>({
    nodeType: BLOCKS.DOCUMENT,
    content: [],
    data: {},
  });
  const [modalReviewerShortname, setShortName] = useState('');
  const [modalReviewerLocation, setLocation] = useState('');

  const openModal = (
    text: Document,
    name?: string,
    shortName?: string,
    location?: string
  ): void => {
    setModalText(text);
    setDisplayModal(true);
    setShortName(shortName ? shortName : '');
    setLocation(location ? location : '');
    document.body.style.overflow = 'hidden'; // stop background from scrolling
  };

  // This function is passed to the modal component
  const closeModal = (): void => {
    setDisplayModal(false);
    document.body.style.overflow = '';
  };
  /*
  -- End Logic for ReviewModal Component --
  */

  const testimonialCards = testimonials.map(
    (
      { fields: { reviewer, reviewerShortname, reviewerLocation, testimonial, fiveStar } },
      index
    ) => {
      function handleTestimonial(testimonial: Document): JSX.IntrinsicElements['p'] | ReactElement {
        const docToString = documentToPlainTextString(testimonial);

        if (docToString.length > 240) {
          const truncatedTestimonial = docToString.slice(0, 240) + '...';

          return (
            <p className="text-sm leading-snug md:text-lg h-48 md:px-10 px-3">
              {truncatedTestimonial} {readMore()}
            </p>
          );
        } else {
          return (
            <RichText
              document={testimonial}
              classNames="text-sm leading-snug md:text-lg h-48 md:px-10 px-3"
            />
          );
        }
      }

      function readMore(): JSX.IntrinsicElements['a'] {
        return (
          <a
            role="button"
            tabIndex={0}
            className="text-tertiary-actual cursor-pointer whitespace-no-wrap"
            onClick={() => openModal(testimonial, reviewer, reviewerShortname, reviewerLocation)}
          >
            Read More
          </a>
        );
      }

      // Added the correct shortname.
      if (reviewerShortname != 'undefined') {
        reviewerShortname = reviewerShortname.replace('<span>', '');
        reviewerShortname = reviewerShortname.replace('</span>', '');
      }
      return (
        <li key={index} className="inline-block px-1 text-black" id={`card${index}`}>
          <div
            className={`${styles.cardSizing} flex flex-col justify-between max-w-xs overflow-hidden bg-white shadow-lg text-center rounded py-4 px-4`}
          >
            <div className="flex flex-row justify-center mb-2">
              <QuotesSVG />
            </div>
            {handleTestimonial(testimonial)}
            <div>
              <div className="text-gray-700 font-bold mb-1">{reviewerShortname}</div>
              <div className="text-gray-500 text-sm">{reviewerLocation}</div>
              {fiveStar && <Score reviewOutOfFive={fiveStar} />}
            </div>
          </div>
        </li>
      );
    }
  );

  return (
    <div
      className={`flex flex-col bg-${backgroundColor} md:py-8 py-5 m-auto p-auto text-${backgroundColor}`}
    >
      <h2 className="flex lg:mx-32 md:mx-20 mx-10 md:pb-6 font-heading font-semibold text-3xl lg:text-4xl">
        {title}
      </h2>

      {displayModal && (
        <ReviewModal
          text={modalText}
          closeModal={closeModal}
          reviewerShortname={modalReviewerShortname}
          reviewerLocation={modalReviewerLocation}
        />
      )}
      <div className="flex flex-row">
        <button
          aria-label="scroll left"
          disabled={scroll < 1 || arrowDisabled === true}
          onClick={() => scroller('left')}
          className={`hidden md:block self-center lg:ml-10 md:ml-5 mb-8 ${
            scroll < 1 ? 'opacity-25' : ''
          }`}
        >
          <ChevronLeftIcon style={{ fontSize: 60 }} />
        </button>
        <div
          ref={refContainer}
          className={`flex md:overflow-x-hidden overflow-x-scroll py-5 hide-scroll-bar mx-5 ${styles.cardContainerShadow}`}
        >
          <ul className={`flex flex-nowrap md:ml-5 md:pr-5 ml-1`}>{testimonialCards}</ul>
        </div>
        <button
          aria-label="scroll right"
          disabled={scroll === testimonials.length - 1 || arrowDisabled === true}
          onClick={() => scroller('right')}
          className={`hidden md:block self-center lg:mr-10 md:mr-5 mb-8 ${
            scroll < testimonials.length - 2 ? '' : 'md:opacity-25'
          } ${scroll < testimonials.length - 1 ? '' : 'opacity-25'}`}
        >
          <ChevronRightIcon style={{ fontSize: 60 }} />
        </button>
      </div>
    </div>
  );
}
