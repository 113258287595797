import { ReactElement } from 'react';
import RichText from 'components/UI/Richtext';
import type { Document } from '@contentful/rich-text-types';
import QuotesSVG from './QuotesSVG';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';

export interface ReviewModalProps {
  text: Document;
  closeModal: () => void;
  reviewerShortname: string;
  reviewerLocation?: string;
}

export default function ReviewModal({
  text,
  closeModal,
  reviewerShortname,
  reviewerLocation,
}: ReviewModalProps): ReactElement {
  if (reviewerShortname != 'undefined') {
    reviewerShortname = reviewerShortname.replace('<span>', '');
    reviewerShortname = reviewerShortname.replace('</span>', '');
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          closeModal();
        }
      }}
      className={`${styles.modalBackground} fixed top-0 left-0 h-full w-full z-20 flex items-center justify-center`}
    >
      <div className="w-10/12 md:w-1/2 md:h-3/5 bg-white flex items-center flex-col p-4 relative cursor-default text-black">
        <CloseIcon
          className="absolute top-0 right-0 mr-2 mt-3 cursor-pointer"
          style={{ fontSize: '2rem', color: 'disabled' }}
          onClick={() => closeModal()}
        />
        <div className="w-80">
          <QuotesSVG />
        </div>

        <div className={`${styles.modalBody} my-4 overflow-auto w-full`}>
          {text && <RichText document={text} />}
        </div>
        <div className="flex flex-col items-center">
          <div className="text-gray-700 font-bold mb-1">{reviewerShortname}</div>
          <div className="text-gray-500 text-sm">
            {reviewerLocation}
            <a
              role="button"
              tabIndex={0}
              className="text-tertiary-actual ml-4"
              onClick={() => closeModal()}
            >
              Back to reviews
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
