import { ReactElement } from 'react';
import Link from '../Link';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

export type ScoreProps = {
  reviewCount?: number;
  reviewOutOfFive: number;
  ratingStatement?: string;
} & ({ locationId?: string } | { doctorId?: string });

function formatStars(reviewScore: number): JSX.Element[] {
  const isInteger = Number.isInteger(reviewScore);
  const wholeNumber = isInteger ? reviewScore : Math.floor(reviewScore);
  const decimalValue = reviewScore % 1;
  const halfStar = !isInteger && decimalValue >= 0.25 ? true : false;
  const stars = [];

  for (let i = 0; i < wholeNumber; i++) {
    stars.push(<StarIcon key={i} style={{ color: 'gold', fontSize: '1.2em' }} />);
  }

  if (halfStar) {
    stars.push(<StarHalfIcon key="half-star" style={{ color: 'gold', fontSize: '1.2em' }} />);
  }

  const emptyStars = 5 - stars.length;

  if (emptyStars) {
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <StarOutlineIcon key={`star-outline${i}`} style={{ color: 'gold', fontSize: '1.2em' }} />
      );
    }
  }

  return stars;
}

export default function Score(props: ScoreProps): ReactElement {
  return (
    <span className="text-sm text-primary-actual flex flex-col md:flex-row items-center justify-center">
      {props.ratingStatement && (
        <div className="font-bold md:mr-6 pt-2">{props.ratingStatement}</div>
      )}
      <div className="text-3xl">{formatStars(props.reviewOutOfFive)}</div>
      {props.reviewCount && (
        <div className="md:ml-6 pt-2">
          <Link slug="reviews" query={props}>
            <a className="text-tertiary-actual cursor-pointer">
              Read All {props.reviewCount} Reviews
            </a>
          </Link>
        </div>
      )}
    </span>
  );
}
