import { ReactElement } from 'react';

import Image from './Image';
import ImageLinkBlock from '../UI/ImageLinkBlock';
import { PhotoBlockProps } from 'components/Contentful/PhotoGallery';

interface PhotoGalleryBlockProps extends PhotoBlockProps {
  width: number;
  query?: string;
}

export default function PhotoGalleryBlock({
  image,
  rowOneText,
  rowTwoText,
  rowThreeText,
  link,
  openInNewTab,
  width,
  query = '',
}: PhotoGalleryBlockProps): ReactElement {
  return (
    <div className={`w-1/2 lg:w-1/${width} border-transparent mb-4 border-8 lg:mb-6 `}>
      {link ? (
        <ImageLinkBlock
          image={image}
          imageQuery={query}
          openInNewTab={openInNewTab || false}
          classNames="w-full object-cover"
          link={link}
          id="id"
        />
      ) : (
        <Image image={image} query={query} classNames="w-full object-cover" />
      )}
      {(rowOneText || rowTwoText || rowThreeText) && (
        <div className="mt-4 text-sm lg:text-lg">
          {rowOneText && <span className="block">{rowOneText}</span>}
          {rowTwoText && <span className="block">{rowTwoText}</span>}
          {rowThreeText && <span className="block">{rowThreeText}</span>}
        </div>
      )}
    </div>
  );
}
