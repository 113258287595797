import { ReactElement } from 'react';

export default function QuotesSVG(): ReactElement {
  return (
    <svg width="64" height="47" viewBox="0 0 64 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.8867 2V9.38281C25.0312 9.38281 21.4424 10.6338 18.1201 13.1357C14.7979 15.5967 13.1367 18.5498 13.1367 21.9951C13.1367 24.0459 13.957 25.0713 15.5977 25.0713C15.9668 25.0713 16.7051 24.9277 17.8125 24.6406C18.9609 24.3945 19.8838 24.2715 20.5811 24.2715C22.8369 24.2715 24.9492 25.1943 26.918 27.04C28.9277 28.8447 29.9326 31.3467 29.9326 34.5459C29.9326 37.6631 28.9072 40.3496 26.8564 42.6055C24.8057 44.8203 21.9961 45.9277 18.4277 45.9277C14.1621 45.9277 10.7168 44.3281 8.0918 41.1289C5.4668 37.8887 4.1543 33.3975 4.1543 27.6553C4.1543 19.7393 6.55371 13.5459 11.3525 9.0752C16.1924 4.56348 22.0371 2.20508 28.8867 2ZM62.6631 2V9.38281C59.0127 9.38281 55.4648 10.6133 52.0195 13.0742C48.6152 15.4941 46.9131 18.4678 46.9131 21.9951C46.9131 24.0459 47.7334 25.0713 49.374 25.0713C49.7432 25.0713 50.4814 24.9277 51.5889 24.6406C52.7373 24.3945 53.6602 24.2715 54.3574 24.2715C56.7363 24.2715 58.8896 25.2148 60.8174 27.1016C62.7451 28.9883 63.709 31.4697 63.709 34.5459C63.709 37.6631 62.6836 40.3496 60.6328 42.6055C58.582 44.8203 55.7725 45.9277 52.2041 45.9277C47.9385 45.9277 44.4932 44.3281 41.8682 41.1289C39.2432 37.8887 37.9307 33.3975 37.9307 27.6553C37.9307 19.7393 40.3301 13.5459 45.1289 9.0752C49.9688 4.56348 55.8135 2.20508 62.6631 2Z"
        fill="#5FD0DF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="47"
      >
        <path d="M62 0H0V47H62V0Z" fill="white" />
        <path
          d="M25.7324 2V9.38281C21.877 9.38281 18.2881 10.6338 14.9658 13.1357C11.6436 15.5967 9.98242 18.5498 9.98242 21.9951C9.98242 24.0459 10.8027 25.0713 12.4434 25.0713C12.8125 25.0713 13.5508 24.9277 14.6582 24.6406C15.8066 24.3945 16.7295 24.2715 17.4268 24.2715C19.6826 24.2715 21.7949 25.1943 23.7637 27.04C25.7734 28.8447 26.7783 31.3467 26.7783 34.5459C26.7783 37.6631 25.7529 40.3496 23.7021 42.6055C21.6514 44.8203 18.8418 45.9277 15.2734 45.9277C11.0078 45.9277 7.5625 44.3281 4.9375 41.1289C2.3125 37.8887 1 33.3975 1 27.6553C1 19.7393 3.39941 13.5459 8.19824 9.0752C13.0381 4.56348 18.8828 2.20508 25.7324 2ZM59.5088 2V9.38281C55.8584 9.38281 52.3105 10.6133 48.8652 13.0742C45.4609 15.4941 43.7588 18.4678 43.7588 21.9951C43.7588 24.0459 44.5791 25.0713 46.2197 25.0713C46.5889 25.0713 47.3271 24.9277 48.4346 24.6406C49.583 24.3945 50.5059 24.2715 51.2031 24.2715C53.582 24.2715 55.7354 25.2148 57.6631 27.1016C59.5908 28.9883 60.5547 31.4697 60.5547 34.5459C60.5547 37.6631 59.5293 40.3496 57.4785 42.6055C55.4277 44.8203 52.6182 45.9277 49.0498 45.9277C44.7842 45.9277 41.3389 44.3281 38.7139 41.1289C36.0889 37.8887 34.7764 33.3975 34.7764 27.6553C34.7764 19.7393 37.1758 13.5459 41.9746 9.0752C46.8145 4.56348 52.6592 2.20508 59.5088 2Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M25.7324 2V9.38281C21.877 9.38281 18.2881 10.6338 14.9658 13.1357C11.6436 15.5967 9.98242 18.5498 9.98242 21.9951C9.98242 24.0459 10.8027 25.0713 12.4434 25.0713C12.8125 25.0713 13.5508 24.9277 14.6582 24.6406C15.8066 24.3945 16.7295 24.2715 17.4268 24.2715C19.6826 24.2715 21.7949 25.1943 23.7637 27.04C25.7734 28.8447 26.7783 31.3467 26.7783 34.5459C26.7783 37.6631 25.7529 40.3496 23.7021 42.6055C21.6514 44.8203 18.8418 45.9277 15.2734 45.9277C11.0078 45.9277 7.5625 44.3281 4.9375 41.1289C2.3125 37.8887 1 33.3975 1 27.6553C1 19.7393 3.39941 13.5459 8.19824 9.0752C13.0381 4.56348 18.8828 2.20508 25.7324 2ZM59.5088 2V9.38281C55.8584 9.38281 52.3105 10.6133 48.8652 13.0742C45.4609 15.4941 43.7588 18.4678 43.7588 21.9951C43.7588 24.0459 44.5791 25.0713 46.2197 25.0713C46.5889 25.0713 47.3271 24.9277 48.4346 24.6406C49.583 24.3945 50.5059 24.2715 51.2031 24.2715C53.582 24.2715 55.7354 25.2148 57.6631 27.1016C59.5908 28.9883 60.5547 31.4697 60.5547 34.5459C60.5547 37.6631 59.5293 40.3496 57.4785 42.6055C55.4277 44.8203 52.6182 45.9277 49.0498 45.9277C44.7842 45.9277 41.3389 44.3281 38.7139 41.1289C36.0889 37.8887 34.7764 33.3975 34.7764 27.6553C34.7764 19.7393 37.1758 13.5459 41.9746 9.0752C46.8145 4.56348 52.6592 2.20508 59.5088 2Z"
          stroke="black"
        />
      </g>
    </svg>
  );
}
